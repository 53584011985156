import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import './IFramePage.css';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { useCustomer } from '../CustomerContext'; // Corrected import

const IFramePage = () => {
    const navigate = useNavigate();
    const [iframeUrl, setIframeUrl] = useState('');
    const [pageTitle, setPageTitle] = useState('');
    const location = useLocation();
    const { customerData } = useCustomer(); // Using useCustomer hook

    const handleSignOut = async () => {
        try {
            await Auth.signOut();
            navigate('/signed-out');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const name = queryParams.get('name');

        // Ensure customerData is available
        if (!customerData || !customerData.permissions) {
            console.error("Customer data or permissions are missing");
            return;
        }

        // Find the page in the permissions array that matches the name
        const pagePermission = customerData.permissions.find(
            (permission) => permission.name === name && permission.route === '/iframe'
        );

        if (pagePermission && pagePermission.customUrl) {
            setIframeUrl(pagePermission.customUrl);
            setPageTitle(pagePermission.label);
        } else {
            setIframeUrl('');
            setPageTitle('Page Not Found');
        }
    }, [customerData, location]);

    return (
        <div className="iframePage">
            <Sidebar handleSignOut={handleSignOut} />
            <div className="mainContent">
                <TopBar handleSignOut={handleSignOut} />
                <div className="contentArea">
                    {iframeUrl ? (
                        <iframe
                            title={pageTitle}
                            src={iframeUrl}
                            frameBorder="0"
                            allowFullScreen
                            className="iframeContent"
                        ></iframe>
                    ) : (
                        <p>{pageTitle}</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default IFramePage;
