import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import './YouTube.css';

const YouTubeSummary = () => {
    const [campaignName, setCampaignName] = useState('Campaign 1');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [yesterdayData, setYesterdayData] = useState({});
    const [last7DaysData, setLast7DaysData] = useState({});
    const [monthToDateData, setMonthToDateData] = useState({});
    const [conversionData, setConversionData] = useState([]);
    const [deliveryData, setDeliveryData] = useState([]);

    useEffect(() => {
        const fetchAllData = async () => {
            await fetchData('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/youtube-ads-twelve', processConversionData);
            await new Promise(resolve => setTimeout(resolve, 500));
            await fetchData('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/youtube-ads-yesterday', setYesterdayData, true);
            await new Promise(resolve => setTimeout(resolve, 500));
            await fetchData('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/youtube-ads-seven', setLast7DaysData, true);
            await new Promise(resolve => setTimeout(resolve, 500));
            await fetchData('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/youtube-ads-month', setMonthToDateData, true);
        };

        fetchAllData();
    }, []);

    const fetchData = async (url, setDataFunction, isSummaryData = false) => {
        try {
            const response = await fetch(url);
            const result = await response.json();
            const data = result.data;
            if (isSummaryData) {
                if (data && data.length > 0) {
                    const aggregatedData = data.reduce((acc, item) => {
                        acc.impressions += parseInt(item.impressions || 0);
                        acc.clicks += parseInt(item.clicks || 0);
                        acc.cost += parseFloat(item.cost || 0);
                        acc.conversions += parseFloat(item.conversions || 0);
                        acc.ctr += parseFloat(item.ctr || 0);
                        acc.cpc += parseFloat(item.cpc || 0);
                        return acc;
                    }, {
                        impressions: 0,
                        clicks: 0,
                        cost: 0,
                        conversions: 0,
                        ctr: 0,
                        cpc: 0
                    });

                    setDataFunction({
                        impressions: formatNumber(aggregatedData.impressions),
                        clicks: formatNumber(aggregatedData.clicks),
                        ctr: `${formatNumber(aggregatedData.ctr / data.length, 2)}%`,
                        cost: `$${formatNumber(aggregatedData.cost, 2)}`,
                        cpc: `$${formatNumber(aggregatedData.cpc / data.length, 2)}`,
                        conversions: formatNumber(aggregatedData.conversions)
                    });
                } else {
                    setDataFunction({
                        impressions: 'No Data',
                        clicks: 'No Data',
                        ctr: 'No Data',
                        cost: 'No Data',
                        cpc: 'No Data',
                        conversions: 'No Data'
                    });
                }
            } else {
                setDataFunction(data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const processConversionData = (data) => {
        if (Array.isArray(data) && data.length > 0) {
            const groupedData = data.reduce((acc, item) => {
                const week = new Date(item.segments_date).toISOString().split('T')[0];
                const weekStartDate = new Date(item.segments_date);
                weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay()); // Set to start of the week (Sunday)
                const formattedWeek = weekStartDate.toISOString().split('T')[0];
                
                if (!acc[formattedWeek]) {
                    acc[formattedWeek] = {
                        conversions: 0,
                        cost: 0,
                        impressions: 0,
                        clicks: 0,
                        cpc: 0,
                        ctr: 0
                    };
                }
                acc[formattedWeek].conversions += parseFloat(item.conversions || 0);
                acc[formattedWeek].cost += parseFloat(item.cost || 0);
                acc[formattedWeek].impressions += parseInt(item.impressions || 0);
                acc[formattedWeek].clicks += parseInt(item.clicks || 0);
                acc[formattedWeek].cpc += parseFloat(item.cpc || 0);
                acc[formattedWeek].ctr += parseFloat(item.ctr || 0);
                return acc;
            }, {});

            const parsedConversionData = Object.entries(groupedData).map(([week, data]) => ({
                week,
                conversions: formatNumber(data.conversions),
                cost: `$${formatNumber(data.cost, 2)}`,
                impressions: formatNumber(data.impressions),
                clicks: formatNumber(data.clicks),
                cpc: `$${formatNumber(data.cpc / data.clicks, 2)}`,
                ctr: `${formatNumber((data.clicks / data.impressions) * 100, 2)}%`
            }));

            setConversionData(parsedConversionData);
            setDeliveryData(parsedConversionData);
        } else {
            setConversionData([]);
            setDeliveryData([]);
        }
    };

    const formatNumber = (number, decimals = 0) => {
        return number.toLocaleString('en-US', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <div className="youtubeSummary">
            <Sidebar />
            <div className="mainContent">
                <TopBar />
                <div className="breadcrumbs">
                    <div className="pageName">YouTube Ads Summary</div>
                </div>
                <div className="contentArea">
                    <div className="summaryHeader">
                        <div className="accountName">Account | 8800154807</div>
                        <div className="campaignDropdown">
                            <div className="customSelect" onClick={toggleDropdown}>
                                <span>{campaignName}</span>
                                <span className="dropdownIcon">▼</span>
                            </div>
                            {isDropdownOpen && (
                                <div className="dropdownMenu">
                                    {['Campaign 1', 'Campaign 2', 'Campaign 3'].map(name => (
                                        <div
                                            key={name}
                                            className="dropdownItem"
                                            onClick={() => {
                                                setCampaignName(name);
                                                setIsDropdownOpen(false);
                                            }}
                                        >
                                            {name}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="activitySections">
                        <ActivitySection
                            title="Yesterday"
                            data={yesterdayData}
                        />
                        <ActivitySection
                            title="Last 7 Days (excluding today)"
                            data={last7DaysData}
                        />
                        <ActivitySection
                            title="Month to Date (vs prior MTD, excluding today)"
                            data={monthToDateData}
                        />
                    </div>
                    <div className="weeklyTrends">WEEKLY TRENDS | Last 12 weeks</div>
                    <ConversionTable data={conversionData} />
                    <DeliveryTable data={deliveryData} />
                </div>
            </div>
        </div>
    );
};

const ActivitySection = ({ title, data }) => (
    <div className="activitySection">
        <div className="activityHeader">{title}</div>
        <div className="activityData">
            {Object.entries(data).map(([key, value]) => (
                <div key={key} className="activityItem">
                    <div className="activityLabel">{key}</div>
                    <div className="activityValue">{value}</div>
                </div>
            ))}
        </div>
    </div>
);

const ConversionTable = ({ data }) => {
    const totalConversions = data.reduce((acc, row) => acc + parseFloat(row.conversions.replace(/,/g, '')), 0);
    const totalCost = data.reduce((acc, row) => acc + parseFloat(row.cost.replace(/[^0-9.-]+/g, '')), 0).toFixed(2);
    const totalCPC = (data.reduce((acc, row) => acc + parseFloat(row.cpc.replace(/[^0-9.-]+/g, '')), 0) / data.length).toFixed(2);
    const totalCTR = (data.reduce((acc, row) => acc + parseFloat(row.ctr.replace(/[^0-9.-]+/g, '')), 0) / data.length).toFixed(2);

    return (
        <div className="conversionTable">
            <div className="tableHeader">CONVERSION</div>
            <table>
                <thead>
                    <tr>
                        <th>Week</th>
                        <th>Conversions</th>
                        <th>Cost</th>
                        <th>CPC</th>
                        <th>CTR</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => (
                        <tr key={index}>
                            <td>{row.week}</td>
                            <td>{row.conversions}</td>
                            <td>{row.cost}</td>
                            <td>{row.cpc}</td>
                            <td>{row.ctr}</td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td>Grand total</td>
                        <td>{totalConversions}</td>
                        <td>{`$${totalCost}`}</td>
                        <td>{`$${totalCPC}`}</td>
                        <td>{`${totalCTR}%`}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

const DeliveryTable = ({ data }) => {
    const totalImpressions = data.reduce((acc, row) => acc + parseInt(row.impressions.replace(/,/g, '')), 0);
    const totalClicks = data.reduce((acc, row) => acc + parseInt(row.clicks.replace(/,/g, '')), 0);
    const totalCTR = (data.reduce((acc, row) => acc + parseFloat(row.ctr.replace(/[^0-9.-]+/g, '')), 0) / data.length).toFixed(2);
    const totalCPM = (data.reduce((acc, row) => acc + parseFloat((row.cost.replace(/[^0-9.-]+/g, '')) / row.impressions * 1000), 0) / data.length).toFixed(2);
    const totalCPC = (data.reduce((acc, row) => acc + parseFloat(row.cpc.replace(/[^0-9.-]+/g, '')), 0) / data.length).toFixed(2);

    return (
        <div className="deliveryTable">
            <div className="tableHeader">DELIVERY</div>
            <table>
                <thead>
                    <tr>
                        <th>Week</th>
                        <th>Impressions</th>
                        <th>Clicks</th>
                        <th>CTR</th>
                        <th>CPM</th>
                        <th>CPC</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => (
                        <tr key={index}>
                            <td>{row.week}</td>
                            <td>{row.impressions}</td>
                            <td>{row.clicks}</td>
                            <td>{row.ctr}</td>
                            <td>{row.cpm}</td>
                            <td>{row.cpc}</td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td>Grand total</td>
                        <td>{totalImpressions}</td>
                        <td>{totalClicks}</td>
                        <td>{`${totalCTR}%`}</td>
                        <td>{`$${totalCPM}`}</td>
                        <td>{`$${totalCPC}`}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default YouTubeSummary;

                       
