import React from 'react';
import { Auth } from 'aws-amplify';
import { Navigate } from 'react-router-dom';
import './Index.css';
import logo1 from '../assets/TID-Logo-PinkTab-Email-Sig.png';
import logo2 from '../assets/TID_Files_Login.png';

class Index extends React.Component {
    state = {
        redirectToOverview: false,
    };

    async componentDidMount() {
        try {
            await Auth.currentAuthenticatedUser();
            this.setState({ redirectToOverview: true });
        } catch {
            // Not authenticated
        }
    }

    handleGoogleSignIn = () => {
        Auth.federatedSignIn({ provider: 'Google' });
    };

    render() {
        if (this.state.redirectToOverview) {
            return <Navigate to="/overview" />;
        }

        return (
            <div className="app-container login-page">
                <div className="login-container">
                    <div className="login-box">
                        <div className="left-panel">
                            <div className="logo-container">
                                <img src={logo1} alt="Octopus Logo" className="octopus-logo" />
                                <span className="tap-in-digital">Tap In Digital</span>
                            </div>
                            <p className="welcome-back">Welcome back! Please log in.</p>
                            <img src={logo2} alt="Files with Magnifier" className="files-image" />
                        </div>
                        <div className="right-panel">
                            <h1 className="account-login">Account Login</h1>
                            <p className="sign-in-text">Please sign in below</p>
                            <button onClick={this.handleGoogleSignIn} className="google-signin-button">
                                Sign in with Google
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;
