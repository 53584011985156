import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useCustomer } from '../CustomerContext';
import AdminSidebar from './AdminSidebar'; // Adjust the path if needed
import './AdminSelection.css'; 

const AdminSelection = () => {
    const [customers, setCustomers] = useState([]);
    const navigate = useNavigate();
    const { setCustomer } = useCustomer();

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                // Fetch the list of customers for the admin to impersonate
                const response = await axios.get('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/tid-login');
                setCustomers(response.data);
                console.log('Fetched Customers:', response.data); // Log customer list for debugging
            } catch (error) {
                console.error('Error fetching customers:', error);
            }
        };

        fetchCustomers();
    }, []);

    const handleSelectCustomer = async (customerEmail) => {
        try {
            // Fetch full customer details, including permissions, based on email (not customer_id)
            const response = await axios.get('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/customer-login', {
                params: { email: customerEmail }
            });

            const customerData = response.data;
            console.log('Selected Customer Data:', customerData); // Log customer data for debugging

            // Set the customer context with full data, including permissions
            setCustomer({
                customer_email: customerData.customer_email,
                isAdmin: customerData.is_admin,
                customer_name: customerData.customer_name,
                customer_db: customerData.customer_db,
                permissions: customerData.permissions || []
            });
            
            // Navigate to the overview page once customer is set
            navigate('/overview');
        } catch (error) {
            console.error('Error fetching customer details:', error);
        }
    };

    return (
        <div className="admin-container">

            <div className="admin-content">
                {/* Admin Sidebar */}
                <AdminSidebar />

                {/* Main content for Admin Selection */}
                <div className="admin-main-content">
                    <div className="admin-selection-content">
                        <h2>Select a Customer to Impersonate</h2>
                        <ul className="customer-list">
                            {customers.map((customer) => (
                                <li key={customer.customer_id}>
                                    <button className="customer-button" onClick={() => handleSelectCustomer(customer.customer_email)}>
                                        {customer.customer_name} ({customer.customer_email})
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminSelection;
