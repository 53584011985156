import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

const SignedOut = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const handleSignOut = async () => {
            try {
                await Auth.signOut();
                console.log('Signed out successfully');
                // You can optionally navigate to the home page or another page after sign out
            } catch (error) {
                console.error('Error signing out: ', error);
            }
        };

        handleSignOut();
    }, [navigate]);

    const handleGoogleSignIn = () => {
        Auth.federatedSignIn({ provider: 'Google' });
    };

    return (
        <div>
            <h1>You have been signed out</h1>
            <p>To sign in with a different Google account, please log out of your current Google account first, then click the button below.</p>
            <button onClick={handleGoogleSignIn}>Sign in with Google</button>
        </div>
    );
};

export default SignedOut;
