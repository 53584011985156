import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import { ComposedChart, Bar, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './MarketingSpend.css';
import moment from 'moment';

const MarketingSpend = () => {
    const [data, setData] = useState([]);
    const [dma, setDma] = useState('TOTAL');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 12;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/marketing-spend', {
                    params: { dma, startDate, endDate }
                });

                const adjustedData = response.data.map(item => ({
                    date: item.date,
                    cpa: item.cpa,
                    grossSignups: item.grossSigned,
                    totalSalesAndMarketing: item.totalSalesAndMarketing,
                    "51100_network_tv": item["51100_network_tv"],
                    "51200_cable": item["51200_cable"],
                    "51300_ott_tv": item["51300_ott_tv"],
                    "51900_tv_vendor_fee": item["51900_tv_vendor_fee"],
                    "51950_tv_media_production": item["51950_tv_media_production"],
                    "52100_radio_advertising": item["52100_radio_advertising"],
                    "52900_radio_vendor_fee": item["52900_radio_vendor_fee"],
                    "53100_internet_ppc": item["53100_internet_ppc"],
                    "53150_internet_vendor_fee_for_ppc": item["53150_internet_vendor_fee_for_ppc"],
                    "53200_internet_seo": item["53200_internet_seo"],
                    "53300_internet_social_media_and_misc": item["53300_internet_social_media_and_misc"],
                    "54100_billboards": item["54100_billboards"],
                    "54900_billboards_vendor_fee": item["54900_billboards_vendor_fee"],
                    "55100_mailouts": item["55100_mailouts"],
                    "55500_outsourced_case_acquisition": item["55500_outsourced_case_acquisition"],
                    "55600_phone_number_call_tacking": item["55600_phone_number_call_tacking"],
                    "56100_promotional_events_and_materials": item["56100_promotional_events_and_materials"],
                    "56200_other_vendor_fees_marketing": item["56200_other_vendor_fees_marketing"],
                    "57100_answering_services": item["57100_answering_services"],
                    "53110_internet_lsa": item["53110_internet_lsa"]
                }));

                // Ensure data is in chronological order (oldest first)
                setData(adjustedData.reverse());
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [dma, startDate, endDate]);

    const formatCurrency = (number) => {
        return `$${number.toFixed(2).toLocaleString('en-US')}`;
    };

    const formatDate = (dateString) => {
        return moment(dateString).format('MMM YYYY');
    };

    const CustomBar = ({ dataKey, ...props }) => {
        return (
            <Bar
                dataKey={dataKey}
                {...props}
                onMouseEnter={(data, index) => {
                    document.body.style.cursor = 'pointer';
                }}
                onMouseLeave={(data, index) => {
                    document.body.style.cursor = 'default';
                }}
                activeShape={(props) => (
                    <rect
                        x={props.x}
                        y={props.y}
                        width={props.width}
                        height={props.height}
                        fill={props.fill}
                        stroke="#fff"
                        strokeWidth={2}
                    />
                )}
            />
        );
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                    <p className="label">{`${formatDate(label)}`}</p>
                    {payload.map((entry, index) => (
                        entry.value > 0 && (
                            <p key={`item-${index}`} style={{ color: entry.color, margin: '5px 0' }}>
                                {`${entry.name}: ${formatCurrency(entry.value)}`}
                            </p>
                        )
                    ))}
                </div>
            );
        }
        return null;
    };

    const CustomTooltipForFirstGraph = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const grossSigned = payload.find(item => item.dataKey === "grossSignups");
            const cpa = payload.find(item => item.dataKey === "cpa");
            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                    <p className="label">{`${formatDate(label)}`}</p>
                    {grossSigned && (
                        <p className="intro">{`Gross Signed: ${grossSigned.value}`}</p>
                    )}
                    {cpa && (
                        <p className="intro">{`CPA: ${formatCurrency(cpa.value)}`}</p>
                    )}
                </div>
            );
        }

        return null;
    };

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="marketingSpend">
            <Sidebar />
            <div className="mainContent">
                <TopBar />
                <div className="breadcrumbs">
                    <div className="pageName">Marketing Spend + Signups</div>
                </div>

                {/* First Graph: Monthly Summary */}
                <div className="chartContainer">
                    <h2 className="chartTitle">Monthly Summary Chart | Signups + Cost Per Acquisition</h2>
                    <ResponsiveContainer width="100%" height={500}>
                        <ComposedChart data={data} margin={{ top: 70, right: 30, left: 20, bottom: 20 }}>
                            <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                            <XAxis dataKey="date" tickFormatter={formatDate} />
                            <YAxis
                                yAxisId="left"
                                orientation="left"
                                domain={[0, 3500]}
                                tickFormatter={(value) => formatCurrency(value).replace('$', '')}
                            />
                            <YAxis
                                yAxisId="right"
                                orientation="right"
                                domain={[0, 2500]}
                                tickFormatter={(value) => formatCurrency(value).replace('$', '')}
                            />
                            <Tooltip content={<CustomTooltipForFirstGraph />} />
                            <Legend
                                verticalAlign="top"
                                align="center"
                                wrapperStyle={{
                                    paddingTop: '10px', // Adjusted for more spacing from the title
                                    paddingBottom: '20px',
                                    lineHeight: '24px'
                                }}
                            />
                            <Bar
                                yAxisId="right"
                                dataKey="grossSignups"
                                fill="#4a90e2"
                                name="Gross Signed"
                                barSize={8}
                            />
                            <Line
                                yAxisId="left"
                                type="monotone"
                                dataKey="cpa"
                                stroke="#ff7300"
                                name="CPA"
                                strokeWidth={2}
                                dot={false}
                            />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>

                {/* Monthly Summary Table */}
                <div className="summaryTable">
                    <h2>Monthly Summary</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>MONTH</th>
                                <th>SPEND</th>
                                <th>GROSS SIGN UPS</th>
                                <th>CPA ($)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentRows.map((row, index) => (
                                <tr key={index}>
                                    <td>{formatDate(row.date)}</td>
                                    <td>{formatCurrency(row.totalSalesAndMarketing)}</td>
                                    <td>{row.grossSignups}</td>
                                    <td>{formatCurrency(row.cpa)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="pagination">
                        <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
                            &laquo;
                        </button>
                        {[...Array(Math.ceil(data.length / rowsPerPage))].map((_, index) => (
                            <button
                                key={index + 1}
                                onClick={() => paginate(index + 1)}
                                className={currentPage === index + 1 ? 'active' : ''}
                            >
                                {index + 1}
                            </button>
                        ))}
                        <button
                            onClick={() => paginate(currentPage + 1)}
                            disabled={currentPage === Math.ceil(data.length / rowsPerPage)}
                        >
                            &raquo;
                        </button>
                    </div>
                </div>

                {/* Second Graph: Digital Paid Media */}
                <div className="chartContainer">
                    <h2 className="chartTitle">Digital Paid Media</h2>
                    <ResponsiveContainer width="100%" height={500}>
                        <ComposedChart data={data} margin={{ top: 70, right: 30, left: 20, bottom: 20 }}>
                            <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                            <XAxis dataKey="date" tickFormatter={formatDate} />
                            <YAxis domain={[0, 600000]} tickFormatter={(value) => `${value / 1000}K`} />
                            <Tooltip content={<CustomTooltip />} cursor={{ fill: 'rgba(0, 0, 0, 0.1)' }} />
                            <Legend
                                verticalAlign="top"
                                align="center"
                                wrapperStyle={{
                                    paddingTop: '10px', // Adjusted for more spacing from the title
                                    paddingBottom: '20px',
                                    lineHeight: '24px'
                                }}
                            />
                            <Bar dataKey="53100_internet_ppc" stackId="a" fill="#4a90e2" />
                            <Bar dataKey="53150_internet_vendor_fee_for_ppc" stackId="a" fill="#50e3c2" />
                            <Bar dataKey="53200_internet_seo" stackId="a" fill="#bd10e0" />
                            <Bar dataKey="53300_internet_social_media_and_misc" stackId="a" fill="#f5a623" />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>

                {/* Third Graph: TV + Radio */}
                <div className="chartContainer">
                    <h2 className="chartTitle">TV + Radio</h2>
                    <ResponsiveContainer width="100%" height={500}>
                        <ComposedChart data={data} margin={{ top: 70, right: 30, left: 20, bottom: 20 }}>
                            <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                            <XAxis dataKey="date" tickFormatter={formatDate} />
                            <YAxis domain={[0, 1000000]} tickFormatter={(value) => `${value / 1000}K`} />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend
                                verticalAlign="top"
                                align="center"
                                wrapperStyle={{
                                    paddingTop: '10px', // Adjusted for more spacing from the title
                                    paddingBottom: '20px',
                                    lineHeight: '24px'
                                }}
                            />
                            <Bar dataKey="51100_network_tv" stackId="a" fill="#4a90e2" activeDot={{ r: 8, strokeWidth: 2, stroke: '#fff' }} />
                            <Bar dataKey="51200_cable" stackId="a" fill="#50e3c2" activeDot={{ r: 8, strokeWidth: 2, stroke: '#fff' }} />
                            <Bar dataKey="51300_ott_tv" stackId="a" fill="#bd10e0" activeDot={{ r: 8, strokeWidth: 2, stroke: '#fff' }} />
                            <Bar dataKey="51900_tv_vendor_fee" stackId="a" fill="#f5a623" activeDot={{ r: 8, strokeWidth: 2, stroke: '#fff' }} />
                            <Bar dataKey="51950_tv_media_production" stackId="a" fill="#ff7f0e" activeDot={{ r: 8, strokeWidth: 2, stroke: '#fff' }} />
                            <Bar dataKey="52100_radio_advertising" stackId="a" fill="#2ca02c" activeDot={{ r: 8, strokeWidth: 2, stroke: '#fff' }} />
                            <Bar dataKey="52900_radio_vendor_fee" stackId="a" fill="#9467bd" activeDot={{ r: 8, strokeWidth: 2, stroke: '#fff' }} />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>

                {/* Fourth Graph: Billboards + All Others */}
                <div className="chartContainer">
                    <h2 className="chartTitle">Billboards + All Others</h2>
                    <ResponsiveContainer width="100%" height={500}>
                        <ComposedChart data={data} margin={{ top: 70, right: 30, left: 20, bottom: 20 }}>
                            <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                            <XAxis dataKey="date" tickFormatter={formatDate} />
                            <YAxis domain={[0, 400000]} tickFormatter={(value) => `${value / 1000}K`} />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend
                                verticalAlign="top"
                                align="center"
                                wrapperStyle={{
                                    paddingTop: '10px', // Adjusted for more spacing from the title
                                    paddingBottom: '20px',
                                    lineHeight: '24px'
                                }}
                            />
                            <Bar dataKey="54100_billboards" stackId="a" fill="#4a90e2" activeDot={{ r: 8, strokeWidth: 2, stroke: '#fff' }} />
                            <Bar dataKey="54900_billboards_vendor_fee" stackId="a" fill="#50e3c2" activeDot={{ r: 8, strokeWidth: 2, stroke: '#fff' }} />
                            <Bar dataKey="55100_mailouts" stackId="a" fill="#bd10e0" activeDot={{ r: 8, strokeWidth: 2, stroke: '#fff' }} />
                            <Bar dataKey="55600_phone_number_call_tacking" stackId="a" fill="#f5a623" activeDot={{ r: 8, strokeWidth: 2, stroke: '#fff' }} />
                            <Bar dataKey="56100_promotional_events_and_materials" stackId="a" fill="#2ca02c" activeDot={{ r: 8, strokeWidth: 2, stroke: '#fff' }} />
                            <Bar dataKey="56200_other_vendor_fees_marketing" stackId="a" fill="#9467bd" activeDot={{ r: 8, strokeWidth: 2, stroke: '#fff' }} />
                            <Bar dataKey="57100_answering_services" stackId="a" fill="#1f77b4" activeDot={{ r: 8, strokeWidth: 2, stroke: '#fff' }} />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
};

export default MarketingSpend;
