import React, { useState, useEffect } from 'react';
import AdminSidebar from './AdminSidebar';
import './CustomerEnrollment.css';

const CustomerEnrollment = () => {
    const [customers, setCustomers] = useState([]);
    const [formData, setFormData] = useState({
        customer_email: '',
        customer_name: '',
        customer_db: '',
        is_admin: false,
        is_active: true
    });
    const [isEditing, setIsEditing] = useState(false);
    const [alert, setAlert] = useState({ show: false, message: '', type: '' });

    useEffect(() => {
        fetchCustomers();
    }, []);

    const fetchCustomers = async () => {
        try {
            const response = await fetch('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/customer-crud');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch customers');
            }
            const data = await response.json();
            setCustomers(data);
        } catch (error) {
            console.error('Error fetching customers:', error);
            showAlert(error.message, 'error');
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let url = 'https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/customer-crud';
            let method = 'POST';
            if (isEditing) {
                url += `/${formData.customer_id}`;
                method = 'PUT';
            }

            // Exclude customer_id from the request body when creating a new customer
            const { customer_id, ...payload } = formData;

            const response = await fetch(url, {
                method,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to save customer');
            }

            await fetchCustomers();
            resetForm();
            showAlert(isEditing ? 'Customer updated successfully' : 'Customer added successfully', 'success');
        } catch (error) {
            console.error('Error saving customer:', error);
            showAlert(error.message, 'error');
        }
    };

    const handleEdit = (customer) => {
        setFormData(customer);
        setIsEditing(true);
    };

    const handleDelete = async (customerId) => {
        if (window.confirm('Are you sure you want to delete this customer?')) {
            try {
                const response = await fetch(`https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/customer-crud/${customerId}`, {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                });
                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'Failed to delete customer');
                }
                await fetchCustomers();
                showAlert('Customer deleted successfully', 'success');
            } catch (error) {
                console.error('Error deleting customer:', error);
                showAlert(error.message, 'error');
            }
        }
    };

    const resetForm = () => {
        setFormData({
            customer_email: '',
            customer_name: '',
            customer_db: '',
            is_admin: false,
            is_active: true
        });
        setIsEditing(false);
    };

    const showAlert = (message, type) => {
        setAlert({ show: true, message, type });
        setTimeout(() => setAlert({ show: false, message: '', type: '' }), 5000);
    };

    return (
        <div className="admin-container">
            <div className="admin-content">
                <AdminSidebar />
                <div className="admin-main-content">
                    <h2>Manage Customers</h2>
                    {alert.show && (
                        <div className={`alert ${alert.type}`}>
                            {alert.message}
                        </div>
                    )}
                    <div className="customer-enrollment-form-container">
                        <form onSubmit={handleSubmit} className="customer-form">
                            <input
                                type="email"
                                name="customer_email"
                                value={formData.customer_email}
                                onChange={handleInputChange}
                                placeholder="Email"
                                required
                            />
                            <input
                                type="text"
                                name="customer_name"
                                value={formData.customer_name}
                                onChange={handleInputChange}
                                placeholder="Name"
                                required
                            />
                            <input
                                type="text"
                                name="customer_db"
                                value={formData.customer_db}
                                onChange={handleInputChange}
                                placeholder="Database"
                            />
                            <label>
                                <input
                                    type="checkbox"
                                    name="is_admin"
                                    checked={formData.is_admin}
                                    onChange={handleInputChange}
                                />
                                Is Admin
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    name="is_active"
                                    checked={formData.is_active}
                                    onChange={handleInputChange}
                                />
                                Is Active
                            </label>
                            <button type="submit">{isEditing ? 'Update' : 'Add'} Customer</button>
                            {isEditing && <button type="button" onClick={resetForm}>Cancel</button>}
                        </form>
                    </div>
                    <table className="customer-table">
                        <thead>
                            <tr>
                                <th>Email</th>
                                <th>Name</th>
                                <th>Database</th>
                                <th>Admin</th>
                                <th>Active</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {customers.map((customer) => (
                                <tr key={customer.customer_id}>
                                    <td>{customer.customer_email}</td>
                                    <td>{customer.customer_name}</td>
                                    <td>{customer.customer_db}</td>
                                    <td>{customer.is_admin ? 'Yes' : 'No'}</td>
                                    <td>{customer.is_active ? 'Yes' : 'No'}</td>
                                    <td>
                                        <button onClick={() => handleEdit(customer)}>Edit</button>
                                        <button onClick={() => handleDelete(customer.customer_id)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default CustomerEnrollment;
