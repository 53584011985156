import React, { useState, useRef, useEffect } from 'react';
import { useCustomer } from '../CustomerContext'; 
import './TopBar.css';
import elipseLogo from '../assets/elipse_logo_top_right.png';

const TopBar = ({ handleSignOut }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const { customerData } = useCustomer(); 

    useEffect(() => {
        console.log('Customer Data in TopBar:', customerData); // Debugging customerData
    }, [customerData]);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Ensure customerData exists before rendering the displayName
    const displayName = customerData
        ? (customerData.customer_name || (customerData.isAdmin ? 'Admin User' : 'User'))
        : 'Loading...';  // Show 'Loading...' if customerData is not yet available

    return (
        <div className="topbar">
            <div className="topbarRight">
                <div className="dataDianaParent">
                    {/* Always display the username, fallback to 'Loading...' or 'Admin User' */}
                    <div className="dataDiana">{displayName}</div> 
                    
                    {/* Only display the database if customer_db exists */}
                    {customerData?.customer_db && (
                        <div className="analyst">{customerData.customer_db}</div>
                    )}
                </div>
                <div className="logoContainer">
                    <div className="ellipse-div">
                        <img src={elipseLogo} alt="Logo" className="logo" />
                    </div>
                    <div className="dropdown" ref={dropdownRef}>
                        <button 
                            className={`dropbtn ${dropdownOpen ? 'open' : ''}`} 
                            onClick={toggleDropdown}
                        >
                            ▼
                        </button>
                        <div className={`dropdown-content ${dropdownOpen ? 'show' : ''}`}>
                            <a href="#!" onClick={handleSignOut}>Sign Out</a>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopBar;
