import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Sidebar.css';
import SidebarLogo from './SidebarLogo';
import toolsIcon from '../assets/tools_menu_icon.png';
import channelsIcon from '../assets/channels_menu_icon.png';
import { useCustomer } from '../CustomerContext';

const Sidebar = ({ handleSignOut }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { customerData } = useCustomer();

    const isActive = (path) => location.pathname === path || location.pathname + location.search === path;

    // Ensure customerData is available and has the correct structure
    if (!customerData || !customerData.permissions) {
        console.error("Customer data or permissions are missing");
        return <div>Loading...</div>;
    }

    console.log("Customer Permissions:", customerData.permissions);

    // Filter and group the tools and channels dynamically from customerData.permissions
    const toolsItems = customerData.permissions.filter(item =>
        ['overview', 'spend_simulations', 'marketing_spend_cases', 'marketing_spend', 'predicted', 'articleperformance', 'marketingattribution'].includes(item.name)
    );

    const channelsItems = customerData.permissions.filter(item =>
        ['google_ads_ga', 'googleads', 'google_analytics', 'media_summary', 'media_activity', 'google_ads_sc', 'facebook_ads', 'youtube_ads', 'google_analytics_external', 'webtraffic'].includes(item.name)
    );

    // Function to construct the correct path
    const constructPath = (item) => {
        let path = item.route;
        if (path === '/iframe') {
            // Append the name as a query parameter
            path += `?name=${item.name}`;
        }
        return path;
    };

    return (
        <div className="sidebar">
            <SidebarLogo /> {/* Use the SidebarLogo component */}
            
            {/* Tools Group */}
            {toolsItems.length > 0 && (
                <div className="menuContainer">
                    <div className="leftMenuIconParent">
                        <img className="leftMenuIcons" alt="Tools" src={toolsIcon} />
                        <div className="tools">Tools</div>
                    </div>
                    {toolsItems.map(item => (
                        <div
                            key={item.name}
                            className={`sidebarButton ${isActive(constructPath(item)) ? 'active' : ''}`}
                            onClick={() => navigate(constructPath(item))}
                        >
                            <div className="sidebarNav">{item.label}</div>
                        </div>
                    ))}
                </div>
            )}

            {/* Channels Group */}
            {channelsItems.length > 0 && (
                <div className="menuContainer1">
                    <div className="layersParent">
                        <img className="leftMenuIcons" alt="Channels" src={channelsIcon} />
                        <div className="channels">Channels</div>
                    </div>
                    {channelsItems.map(item => (
                        <div
                            key={item.name}
                            className={`sidebarButton ${isActive(constructPath(item)) ? 'active' : ''}`}
                            onClick={() => navigate(constructPath(item))}
                        >
                            <div className="sidebarNav">{item.label}</div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Sidebar;
