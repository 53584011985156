import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ResponsiveContainer, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import './MarketingSimulations.css';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import simulationLogo from '../assets/simulation_logo.png';

const MarketingSimulations = () => {
    const navigate = useNavigate();
    const [simulationData, setSimulationData] = useState(null);
    const [filteredSimulationData, setFilteredSimulationData] = useState(null);
    const [selectedModelRunDate, setSelectedModelRunDate] = useState('');
    const [selectedTotalBudget, setSelectedTotalBudget] = useState('');
    const [selectedMaxChange, setSelectedMaxChange] = useState('');
    const [dropdownData, setDropdownData] = useState({
        modelRunDates: [],
        totalBudgets: [],
        maxChangeInChannelSpends: []
    });

    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const response = await axios.get('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/marketing-simulation-keys');
                const modelRunDates = response.data.modelRunDates;
                const initialDate = Object.keys(modelRunDates)[0];

                setDropdownData(modelRunDates);

                setSelectedModelRunDate(initialDate);
                setSelectedTotalBudget(Math.max(...modelRunDates[initialDate].totalBudgets.map(Number)));
                setSelectedMaxChange(Math.min(...modelRunDates[initialDate].maxChangeInChannelSpends.map(Number)));
            } catch (error) {
                console.error('Error fetching dropdown data:', error);
            }
        };

        fetchDropdownData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (selectedModelRunDate && selectedTotalBudget && selectedMaxChange) {
                    await new Promise(resolve => setTimeout(resolve, 200)); // Small delay between API calls

                    // Fetch simulation totals
                    const totalsResponse = await axios.get('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/marketing-simulation-totals', {
                        params: {
                            modelRunDate: selectedModelRunDate,
                            totalBudget: selectedTotalBudget,
                            maxChange: selectedMaxChange
                        }
                    });

                    setFilteredSimulationData(totalsResponse.data);
                    await new Promise(resolve => setTimeout(resolve, 200)); // Small delay between API calls

                    // Fetch simulation data by channel
                    const simulationResponse = await axios.get('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/marketing-simulations', {
                        params: {
                            modelRunDate: selectedModelRunDate,
                            totalBudget: selectedTotalBudget,
                            maxChange: selectedMaxChange
                        }
                    });

                    setSimulationData(simulationResponse.data);
                }
            } catch (error) {
                console.error('Error fetching simulation data:', error);
            }
        };

        fetchData();
    }, [selectedModelRunDate, selectedTotalBudget, selectedMaxChange]);

    const handleSignOut = async () => {
        try {
            await Auth.signOut();
            navigate('/signed-out');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const handleModelRunDateChange = (e) => {
        const newDate = e.target.value;
        setSelectedModelRunDate(newDate);

        const budgets = dropdownData[newDate].totalBudgets.map(Number);
        const maxChanges = dropdownData[newDate].maxChangeInChannelSpends.map(Number);

        setSelectedTotalBudget(Math.max(...budgets));
        setSelectedMaxChange(Math.min(...maxChanges));
    };

    const handleTotalBudgetChange = (e) => {
        setSelectedTotalBudget(Number(e.target.value));
    };

    const handleMaxChangeChange = (e) => {
        setSelectedMaxChange(Number(e.target.value));
    };

    if (!filteredSimulationData) {
        return <div>Loading...</div>;
    }

    const formatNumber = (number) => {
        if (number === undefined) return 'N/A';
        return Math.round(number).toLocaleString('en-US');
    };

    const formatPercentage = (number) => {
        if (number === undefined) return 'N/A';
        return number.toFixed(2) + '%';
    };

    const formatCurrency = (number) => {
        if (number === undefined) return 'N/A';
        return `$${Math.round(number).toLocaleString('en-US')}`;
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' });
    };

    return (
        <div className="marketingSimulations">
            <Sidebar handleSignOut={handleSignOut} />
            <div className="mainContent">
                <TopBar handleSignOut={handleSignOut} />
                <div className="breadcrumbs">
                    <div className="pageName">Marketing Simulations</div>
                </div>
                <div className="contentArea">
                    <p className="subtitle">Attributed impact of changes to total budget and channel allocations.</p>
                    <div className="filterSection">
                        <div className="filterItem">
                            <label htmlFor="modelRunDate">Model Run Date</label>
                            <select id="modelRunDate" value={selectedModelRunDate} onChange={handleModelRunDateChange}>
                                {Object.keys(dropdownData).map((date, index) => (
                                    <option key={index} value={date}>{formatDate(date)}</option>
                                ))}
                            </select>
                        </div>
                        <div className="filterItem">
                            <label htmlFor="totalBudget">Total Budget (% of Actual)</label>
                            <select id="totalBudget" value={selectedTotalBudget} onChange={handleTotalBudgetChange}>
                                {dropdownData[selectedModelRunDate]?.totalBudgets.map((budget, index) => (
                                    <option key={index} value={budget}>{budget}</option>
                                ))}
                            </select>
                        </div>
                        <div className="filterItem">
                            <label htmlFor="maxChange">Max Change in Channel Spend</label>
                            <select id="maxChange" value={selectedMaxChange} onChange={handleMaxChangeChange}>
                                {dropdownData[selectedModelRunDate]?.maxChangeInChannelSpends.map((change, index) => (
                                    <option key={index} value={change}>{change}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="simulationCard">
                        <div className="simulationHeader">
                            <div className="headerIcon">
                                <img src={simulationLogo} alt="Simulation Logo" />
                            </div>
                            <h2 className="headerTitle">Simulation</h2>
                            <div className="headerDots">
                                <div className="dot"></div>
                                <div className="dot"></div>
                                <div className="dot"></div>
                            </div>
                            <div className="headerArrow">
                                {/* Add your SVG arrow here */}
                            </div>
                        </div>
                        <div className="simulationContent">
                            <div className="dateRange">Start: {formatDate(filteredSimulationData.simStart)} &nbsp; End: {formatDate(filteredSimulationData.simEnd)}</div>
                            <table className="simulationTable">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Actual</th>
                                        <th>Optimized</th>
                                        <th>Absolute Change</th>
                                        <th>Relative Change</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="metricName">Sales</td>
                                        <td>{formatNumber(filteredSimulationData.actualSales)}</td>
                                        <td>{formatNumber(filteredSimulationData.optimizedSales)}</td>
                                        <td className={filteredSimulationData.optimizedSales - filteredSimulationData.actualSales >= 0 ? 'positive' : 'negative'}>
                                            {formatNumber(filteredSimulationData.optimizedSales - filteredSimulationData.actualSales)}
                                        </td>
                                        <td className={((filteredSimulationData.optimizedSales - filteredSimulationData.actualSales) / filteredSimulationData.actualSales * 100) >= 0 ? 'positive' : 'negative'}>
                                            {formatPercentage(((filteredSimulationData.optimizedSales - filteredSimulationData.actualSales) / filteredSimulationData.actualSales * 100))}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="metricName">Spend</td>
                                        <td>{formatCurrency(filteredSimulationData.actualSpend)}</td>
                                        <td>{formatCurrency(filteredSimulationData.optimizedSpend)}</td>
                                        <td className={filteredSimulationData.optimizedSpend - filteredSimulationData.actualSpend >= 0 ? 'positive' : 'negative'}>
                                            {formatCurrency(filteredSimulationData.optimizedSpend - filteredSimulationData.actualSpend)}
                                        </td>
                                        <td className={((filteredSimulationData.optimizedSpend - filteredSimulationData.actualSpend) / filteredSimulationData.actualSpend * 100) >= 0 ? 'positive' : 'negative'}>
                                            {formatPercentage(((filteredSimulationData.optimizedSpend - filteredSimulationData.actualSpend) / filteredSimulationData.actualSpend * 100))}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="metricName">CPA</td>
                                        <td>{formatCurrency(filteredSimulationData.actualCpa)}</td>
                                        <td>{formatCurrency(filteredSimulationData.optimalCpa)}</td>
                                        <td className={filteredSimulationData.optimalCpa - filteredSimulationData.actualCpa >= 0 ? 'positive' : 'negative'}>
                                            {formatCurrency(filteredSimulationData.optimalCpa - filteredSimulationData.actualCpa)}
                                        </td>
                                        <td className={((filteredSimulationData.optimalCpa - filteredSimulationData.actualCpa) / filteredSimulationData.actualCpa * 100) >= 0 ? 'positive' : 'negative'}>
                                            {formatPercentage(((filteredSimulationData.optimalCpa - filteredSimulationData.actualCpa) / filteredSimulationData.actualCpa * 100))}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div>
                        <h2>Optimal Sales by Channel</h2>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={simulationData?.salesByChannel || []}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="channel" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="actualSales" fill="#8884d8" />
                                <Bar dataKey="optimizedSales" fill="#82ca9d" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>

                    <div>
                        <h2>Optimal Spend by Channel</h2>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={simulationData?.spendByChannel || []}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="channel" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="actualSpend" fill="#8884d8" />
                                <Bar dataKey="optimizedSpend" fill="#82ca9d" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarketingSimulations;
