// SidebarLogo.js
import React from 'react';
import trackItDataLogo from '../assets/TID-Logo-Mark.png'; // Adjust the path as needed
import './SidebarLogo.css';

const SidebarLogo = () => {
    return (
        <div className="sidebar-logo">
            <img src={trackItDataLogo} alt="Track It Data Logo" className="trackItDataLogoClass" />
            <div className="logo-text">Track.It.Data</div>
        </div>
    );
};

export default SidebarLogo;
