import React, { createContext, useContext, useState, useEffect } from 'react';

const CustomerContext = createContext();

export const useCustomer = () => useContext(CustomerContext);

export const CustomerProvider = ({ children }) => {
    const [customerData, setCustomerData] = useState(null);

    // Debugging log whenever customerData is updated
    useEffect(() => {
        console.log('Customer Data updated in context: ', customerData);
    }, [customerData]);

    const setCustomer = (data) => {
        console.log("Setting Customer Data: ", data); // Log data being set

        setCustomerData({
            customer_email: data.customer_email,
            isAdmin: data.is_admin,
            customer_name: data.customer_name || 'Admin', 
            customer_db: data.is_admin ? null : data.customer_db, 
            permissions: data.permissions || []
        });

        console.log("Customer Data after setCustomer is called: ", customerData); // Ensure data is set correctly
    };

    const clearCustomer = () => {
        console.log('Clearing customer data');
        setCustomerData(null);
    };

    return (
        <CustomerContext.Provider value={{ customerData, setCustomer, clearCustomer }}>
            {children}
        </CustomerContext.Provider>
    );
};
