import React from 'react';
import AdminSidebar from './AdminSidebar'; // Ensure path is correct
import { Outlet } from 'react-router-dom';
import './Admin.css'; // Ensure this file has your required styles

const Admin = () => {
    return (
        <div className="admin-container">

            <div className="admin-content">
                {/* Custom Sidebar for Admin with links */}
                <AdminSidebar />

                {/* Main Content where the selected admin route (Impersonation/CRUD) will be rendered */}
                <div className="admin-main-content">
                    <Outlet /> {/* This renders the page based on the current route (e.g. impersonation, enrollment) */}
                </div>
            </div>
        </div>
    );
};

export default Admin;
