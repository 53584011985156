import React, { useEffect, useState, useMemo } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useCustomer } from './CustomerContext';

const ProtectedRoute = ({ component: Component, isAuthenticated, ...rest }) => {
    const { setCustomer, customerData, clearCustomer } = useCustomer();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const isCustomerSet = useMemo(() => !!customerData, [customerData]);

    useEffect(() => {
        const checkCustomer = async () => {
            try {
                const session = await Auth.currentSession();
                const idToken = session.getIdToken().getJwtToken();
                const decodedToken = jwtDecode(idToken);
                const email = decodedToken.email;

                if (email && !customerData) {
                    const response = await axios.get('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/customer-login', {
                        params: { email }
                    });

                    const fetchedCustomer = response.data;

                    if (fetchedCustomer.is_admin) {
                        // Make second API call for admin details (name, etc.)
                        const adminDetails = await axios.get('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/customer-login', {
                            params: { email }
                        });

                        // Update customer context with admin details
                        setCustomer({
                            isAdmin: true,
                            customer_name: adminDetails.data.customer_name, // Use name from API for admins
                            customer_email: email,
                            permissions: fetchedCustomer.permissions || [],
                        });

                        // Now go to the admin page
                        setLoading(false);
                        navigate('/admin');
                        return;
                    } else {
                        setCustomer(fetchedCustomer);
                    }
                }
            } catch (error) {
                console.error('Error checking customer status:', error);
            } finally {
                setLoading(false);
            }
        };

        if (isAuthenticated && !isCustomerSet) {
            checkCustomer();
        } else {
            setLoading(false);
        }
    }, [isAuthenticated, isCustomerSet, navigate, clearCustomer, setCustomer]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        return <Navigate to="/" />;
    }

    return <Component {...rest} customerData={customerData} />;
};

export default ProtectedRoute;
