const awsmobile = {
    "aws_project_region": "us-west-1",
    "aws_content_delivery_bucket": "tidamplifyapp-20240612152026-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-west-1",
    "aws_content_delivery_url": "https://d2t1tu68r37uo.cloudfront.net"
};
const isLocalhost = window.location.hostname === "localhost";

const awsconfig = {
    Auth: {
        region: 'us-west-1', // Update with your actual region
        userPoolId: 'us-west-1_gPOu6KM2v',
        userPoolWebClientId: '46ga4cc732pg47ksimqk64sh98',
        oauth: {
            domain: 'tiduserpool.auth.us-west-1.amazoncognito.com',
            scope: ['email', 'openid'],
            redirectSignIn: isLocalhost ? 'http://localhost:3000/' : 'https://app.tapindigital.com',
            redirectSignOut: isLocalhost ? 'http://localhost:3000/signed-out' : 'https://app.tapindigital.com/signed-out',
            responseType: 'code' // or 'token'
        }
    }
};

// Merge the two configurations into one
const mergedConfig = {
    ...awsmobile,
    ...awsconfig,
};

export default mergedConfig;
