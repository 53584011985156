// import React, { useState, useEffect } from 'react';
// import { Auth } from 'aws-amplify';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';
// import './Overview.css';
// import Sidebar from './Sidebar';
// import flagIcon from '../assets/flag-alt.png';
// import dropdownIcon from '../assets/ico_dropdown.png';
// import TopBar from './TopBar';

// const Overview = ({ customerData }) => {
//     const navigate = useNavigate();
//     const [isAuthenticated, setIsAuthenticated] = useState(false); 
//     const [modelRunDate, setModelRunDate] = useState('');
//     const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//     const [modelData, setModelData] = useState({});
//     const [modelRunDates, setModelRunDates] = useState([]);
//     const [graphData, setGraphData] = useState([]);
//     const [formattedGraphData, setFormattedGraphData] = useState([]);
//     const [highlightedCategory, setHighlightedCategory] = useState(null);
//     const [additionalSpendData, setAdditionalSpendData] = useState([]);
//     const [additionalSpendAmount, setAdditionalSpendAmount] = useState('');
//     const [saturationCurveData, setSaturationCurveData] = useState([]);
//     const [legendScrollIndex, setLegendScrollIndex] = useState(0);

//     // Retry function to handle transient API errors
//     const retryRequest = async (axiosRequest, retries = 3, delay = 1000) => {
//         for (let i = 0; i < retries; i++) {
//             try {
//                 return await axiosRequest();
//             } catch (error) {
//                 if (i === retries - 1) throw error;
//                 console.warn(`Retry ${i + 1} for ${axiosRequest.url} failed, retrying after ${delay}ms...`);
//                 await new Promise(res => setTimeout(res, delay));
//             }
//         }
//     };

//     // Consolidated data fetching function
//     const fetchData = async (modelRunDate) => {
//         try {
//             //console.log('Starting fetchData function');
//             //console.log('Fetching data for model run date:', modelRunDate);

//             // Fetch Model Data
//             //console.log('Fetching model data...');
//             const modelResponse = await retryRequest(() => axios.get('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/marketing-attribution-summary'));
//             //console.log('Model data received:', modelResponse.data);
//             const modelData = modelResponse.data.reduce((acc, item) => {
//                 acc[item.model_run_date] = {
//                     rsq: parseFloat(item.r_sq).toFixed(2),
//                     nrmse: parseFloat(item.nrmse).toFixed(2),
//                     sales: parseFloat(item.attributed_sales)
//                 };
//                 return acc;
//             }, {});
//             setModelData(modelData);
//             const runDates = modelResponse.data.map(item => item.model_run_date);
//             setModelRunDates(runDates);

//             // Set default modelRunDate if not set
//             if (!modelRunDate && runDates.length > 0) {
//                 setModelRunDate(runDates[0]);
//                 modelRunDate = runDates[0];
//             }

//             // Fetch Graph Data
//             //console.log('Fetching graph data...');
//             const graphResponse = await retryRequest(() => axios.get(`https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/attributed-cases`, {
//                 params: { model_run_date: new Date(modelRunDate).toISOString().split('T')[0] }
//             }));
//             //console.log('Graph data received:', graphResponse.data);
//             const graphData = graphResponse.data.graphData;
//             setGraphData(graphData);
//             formatGraphData(graphData);

//             // Fetch Additional Spend Data
//             //console.log('Fetching additional spend data...');
//             const spendResponse = await retryRequest(() => axios.get(`https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/additional-spend`, {
//                 params: { model_run_date: new Date(modelRunDate).toISOString().split('T')[0] }
//             }));
//             //console.log('Additional spend data received:', spendResponse.data);
//             const additionalSpendData = spendResponse.data.data;
//             setAdditionalSpendData(additionalSpendData);
//             if (additionalSpendData.length > 0) {
//                 setAdditionalSpendAmount(additionalSpendData[0].addl_monthly_spend);
//             }

//             // Fetch Saturation Curve Data
//             //console.log('Fetching saturation curve data...');
//             const saturationResponse = await retryRequest(() => axios.get(`https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/saturation-curves`, {
//                 params: { model_run_date: new Date(modelRunDate).toISOString().split('T')[0] }
//             }));
//             //console.log('Saturation curve data received:', saturationResponse.data);
//             const saturationCurveData = saturationResponse.data.data;
//             setSaturationCurveData(saturationCurveData);

//         } catch (error) {
//             console.error('Error fetching data:', error);
//             alert(`Error fetching data: ${error.message}`); // Alert the user in case of an error
//         }
//     };

//     const formatGraphData = (data) => {
//         //console.log('Formatting graph data:', data);
//         const formattedData = data.reduce((acc, curr) => {
//             const { month, spend_category, attributed_sales } = curr;
//             const monthDate = new Date(month).toISOString().split('T')[0];
//             if (!acc[monthDate]) {
//                 acc[monthDate] = { month: monthDate };
//             }
//             acc[monthDate][spend_category] = parseFloat(attributed_sales);
//             return acc;
//         }, {});

//         // console.log('Formatted graph data:', Object.values(formattedData));
//         setFormattedGraphData(Object.values(formattedData));
//     };

//     useEffect(() => {
//         // Check the authentication state when the component mounts
//         const checkAuth = async () => {
//             try {
//                 const user = await Auth.currentAuthenticatedUser();
//                 setIsAuthenticated(!!user);
//             } catch (err) {
//                 console.log('User not authenticated');
//                 setIsAuthenticated(false);
//             }
//         };
        
//         checkAuth();
//     }, []);

//     useEffect(() => {
//         //console.log('useEffect triggered with isAuthenticated:', isAuthenticated, 'and customerData:', customerData);
//         const loadData = async () => {
//             if (isAuthenticated && customerData) {
//                 await fetchData(modelRunDate);
//             }
//         };

//         loadData();
//     }, [isAuthenticated, customerData, modelRunDate]);


//     const handleSignOut = async () => {
//         try {
//             await Auth.signOut();
//             navigate('/signed-out');
//         } catch (error) {
//             console.error('Error signing out:', error);
//         }
//     };

//     const formatDate = (dateString) => {
//         const options = { year: 'numeric', month: 'short', day: 'numeric' };
//         return new Date(dateString).toLocaleDateString(undefined, options);
//     };

//     const handleChange = (date) => {
//         //console.log('Changing model run date to:', date);
//         setModelRunDate(date);
//         setIsDropdownOpen(false);
//     };

//     const toggleDropdown = () => {
//         //'Toggling dropdown. Current state:', isDropdownOpen);
//         setIsDropdownOpen(!isDropdownOpen);
//     };

//     const handleLegendClick = (dataKey) => {
//         //console.log('Legend item clicked:', dataKey);
//         setHighlightedCategory(dataKey);
//         setTimeout(() => {
//             setHighlightedCategory(null);
//         }, 2000);
//     };

//     const scrollLegendLeft = () => {
//         //console.log('Scrolling legend left');
//         setLegendScrollIndex((prevIndex) => Math.max(prevIndex - 1, 0));
//     };

//     const scrollLegendRight = () => {
//         //console.log('Scrolling legend right');
//         setLegendScrollIndex((prevIndex) => prevIndex + 1);
//     };

//     const renderLegend = (props) => {
//         const { payload } = props;
//         const itemsToShow = 10; // Adjust this value based on the space you have
//         const startIndex = legendScrollIndex;
//         const endIndex = Math.min(legendScrollIndex + itemsToShow, payload.length);
//         const visibleItems = payload.slice(startIndex, endIndex);

//         //console.log('Rendering legend items:', visibleItems);

//         return (
//             <div className="custom-legend">
//                 {startIndex > 0 && <span className="arrow" onClick={scrollLegendLeft}>{"<"}</span>}
//                 {visibleItems.map((entry, index) => (
//                     <span 
//                         key={`item-${index}`} 
//                         style={{ margin: '0 10px', cursor: 'pointer' }} 
//                         onClick={() => handleLegendClick(entry.value)}
//                     >
//                         <span style={{ color: entry.color }}>{entry.value}</span>
//                     </span>
//                 ))}
//                 {endIndex < payload.length && <span className="arrow" onClick={scrollLegendRight}>{">"}</span>}
//             </div>
//         );
//     };

//     return (
//         <div className="marketingAttributionSummary">
//             <Sidebar handleSignOut={handleSignOut} />
//             <div className="mainContent">
//                 <TopBar handleSignOut={handleSignOut} />
//                 <div className="breadcrumbs">
//                     <div className="pageName">Marketing Attribution Summary</div>
//                     <div className="breadcrumbsChild"></div>
//                 </div>
//                 <div className="contentArea">
//                     <div className="driversOfGross">Drivers of gross signed volume</div>
//                     <div className="modelRunDateContainer">
//                         <div className="modelRunDate">Model Run Date</div>
//                         <div className="dropdownWrapper">
//                             <div className="customSelect" onClick={toggleDropdown}>
//                                 <span>{formatDate(modelRunDate)}</span>
//                                 <img className="dropdownIcon" alt="Dropdown Icon" src={dropdownIcon} />
//                             </div>
//                             {isDropdownOpen && (
//                                 <div className="dropdownMenu">
//                                     {modelRunDates.map(date => (
//                                         <div
//                                             key={date}
//                                             className="dropdownItem"
//                                             onClick={() => handleChange(date)}
//                                         >
//                                             {formatDate(date)} - {(modelData[date]?.sales / 1000).toFixed(1)}K
//                                         </div>
//                                     ))}
//                                 </div>
//                             )}
//                         </div>
//                     </div>
//                     <div className="marketingAttributionSummaryInner">
//                         <div className="rectangleParent">
//                             <div className="groupChild"></div>
//                             <div className="flagAltParent">
//                                 <div className="flagIconContainer"></div>
//                                 <div className="modelFit">Model Fit</div>
//                                 <div className="flagIconContainer">
//                                     <img className="flagAltIcon" alt="" src={flagIcon} />
//                                 </div>
//                             </div>
//                             <div className="lineParent">
//                                 <div className="groupItem"></div>
//                                 <div className="rsqParent">
//                                     <div className="nrmse">RSQ</div>
//                                     <b className="b">{modelData[modelRunDate]?.rsq}</b>
//                                 </div>
//                                 <div className="nrmseParent">
//                                     <div className="nrmse">NRMSE</div>
//                                     <b className="b">{modelData[modelRunDate]?.nrmse}</b>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="graphContainer">
//                         <ResponsiveContainer width="100%" height={400}>
//                             <LineChart data={formattedGraphData}>
//                                 <CartesianGrid strokeDasharray="3 3" />
//                                 <XAxis 
//                                     dataKey="month" 
//                                     tickFormatter={(tick) => {
//                                         const date = new Date(tick);
//                                         return `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
//                                     }}
//                                 />
//                                 <YAxis />
//                                 <Tooltip />
//                                 <Legend onClick={(e) => handleLegendClick(e.dataKey)} />
//                                 {formattedGraphData.length > 0 && Object.keys(formattedGraphData[0])
//                                     .filter(key => key !== 'month' && key !== 'model_run_date')
//                                     .map((key, index) => (
//                                         <Line 
//                                             key={key} 
//                                             type="monotone" 
//                                             dataKey={key} 
//                                             stroke={`hsl(${index * 30}, 70%, 50%)`}
//                                             strokeWidth={2}
//                                             opacity={highlightedCategory && highlightedCategory !== key ? 0.2 : 1}
//                                         />
//                                     ))
//                                 }
//                             </LineChart>
//                         </ResponsiveContainer>
//                     </div>
//                     <div className="additionalSpendContainer">
//                         <h3>Impact of Additional Spend</h3>
//                         <p>Annualized signups generated by spending ${additionalSpendAmount} more each month.</p>
//                         <ResponsiveContainer width="100%" height={400}>
//                             <BarChart 
//                                 data={additionalSpendData.sort((a, b) => b.incremental_sales - a.incremental_sales)}
//                                 layout="vertical"
//                                 margin={{ top: 20, right: 30, left: 150, bottom: 20 }}
//                             >
//                                 <CartesianGrid strokeDasharray="3 3" />
//                                 <XAxis type="number" />
//                                 <YAxis type="category" dataKey="spend_category" width={180} />
//                                 <Tooltip />
//                                 <Bar dataKey="incremental_sales" fill="#8884d8" />
//                             </BarChart>
//                         </ResponsiveContainer>
//                     </div>
//                     <div className="saturationCurveContainer">
//                         <h3>Channel-Specific Saturation Curves</h3>
//                         <p>Annualized signups generated by spending ${additionalSpendAmount} more each month.</p>
//                         <ResponsiveContainer width="100%" height={400}>
//                             <LineChart data={saturationCurveData}>
//                                 <CartesianGrid strokeDasharray="3 3" />
//                                 <XAxis type="number" dataKey="spend_adstocked" />
//                                 <YAxis />
//                                 <Tooltip />
//                                 <Legend content={renderLegend} />
//                                 {saturationCurveData.length > 0 && Object.keys(saturationCurveData[0])
//                                     .filter(key => key !== 'model_run_date' && key !== 'spend_adstocked')
//                                     .map((key, index) => (
//                                         <Line 
//                                             key={key} 
//                                             type="monotone" 
//                                             dataKey={key} 
//                                             stroke={`hsl(${index * 30}, 70%, 50%)`}
//                                             strokeWidth={2}
//                                             opacity={highlightedCategory && highlightedCategory !== key ? 0.2 : 1}
//                                             dot={false} // Ensure the line is solid
//                                         />
//                                     ))
//                                 }
//                             </LineChart>
//                         </ResponsiveContainer>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Overview;

import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';
import './Overview.css';
import Sidebar from './Sidebar';
import flagIcon from '../assets/flag-alt.png';
import dropdownIcon from '../assets/ico_dropdown.png';
import TopBar from './TopBar';

const Overview = ({ customerData }) => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false); 
    const [modelRunDate, setModelRunDate] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [modelData, setModelData] = useState({});
    const [modelRunDates, setModelRunDates] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [formattedGraphData, setFormattedGraphData] = useState([]);
    const [highlightedCategory, setHighlightedCategory] = useState(null);
    const [additionalSpendData, setAdditionalSpendData] = useState([]);
    const [additionalSpendAmount, setAdditionalSpendAmount] = useState('');
    const [saturationCurveData, setSaturationCurveData] = useState([]);
    const [legendScrollIndex, setLegendScrollIndex] = useState(0);

    useEffect(() => {
        // Check if customer has "overview" permission
        if (customerData && customerData.permissions) {
            const hasOverviewPermission = customerData.permissions.some(
                (permission) => permission.name === 'overview'
            );
    
            // Redirect to the first available permission or default to 'articleperformance'
            if (!hasOverviewPermission) {
                const nextPermission = customerData.permissions[0];
                const routeToNavigate = nextPermission ? `${nextPermission.route}?name=${nextPermission.name}` : '/iframe?name=articleperformance';
                //const routeToNavigate = '/iframe?name=articleperformance';
                navigate(routeToNavigate);
            }
        }
    }, [customerData, navigate]);

    // Retry function to handle transient API errors
    const retryRequest = async (axiosRequest, retries = 3, delay = 1000) => {
        for (let i = 0; i < retries; i++) {
            try {
                return await axiosRequest();
            } catch (error) {
                if (i === retries - 1) throw error;
                console.warn(`Retry ${i + 1} for ${axiosRequest.url} failed, retrying after ${delay}ms...`);
                await new Promise(res => setTimeout(res, delay));
            }
        }
    };

    const fetchData = async (modelRunDate) => {
        try {
            const modelResponse = await retryRequest(() => axios.get('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/marketing-attribution-summary'));
            const modelData = modelResponse.data.reduce((acc, item) => {
                acc[item.model_run_date] = {
                    rsq: parseFloat(item.r_sq).toFixed(2),
                    nrmse: parseFloat(item.nrmse).toFixed(2),
                    sales: parseFloat(item.attributed_sales)
                };
                return acc;
            }, {});
            setModelData(modelData);
            const runDates = modelResponse.data.map(item => item.model_run_date);
            setModelRunDates(runDates);

            if (!modelRunDate && runDates.length > 0) {
                setModelRunDate(runDates[0]);
                modelRunDate = runDates[0];
            }

            const graphResponse = await retryRequest(() => axios.get(`https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/attributed-cases`, {
                params: { model_run_date: new Date(modelRunDate).toISOString().split('T')[0] }
            }));
            const graphData = graphResponse.data.graphData;
            setGraphData(graphData);
            formatGraphData(graphData);

            const spendResponse = await retryRequest(() => axios.get(`https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/additional-spend`, {
                params: { model_run_date: new Date(modelRunDate).toISOString().split('T')[0] }
            }));
            const additionalSpendData = spendResponse.data.data;
            setAdditionalSpendData(additionalSpendData);
            if (additionalSpendData.length > 0) {
                setAdditionalSpendAmount(additionalSpendData[0].addl_monthly_spend);
            }

            const saturationResponse = await retryRequest(() => axios.get(`https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/saturation-curves`, {
                params: { model_run_date: new Date(modelRunDate).toISOString().split('T')[0] }
            }));
            const saturationCurveData = saturationResponse.data.data;
            setSaturationCurveData(saturationCurveData);

        } catch (error) {
            console.error('Error fetching data:', error);
            alert(`Error fetching data: ${error.message}`);
        }
    };

    const formatGraphData = (data) => {
        const formattedData = data.reduce((acc, curr) => {
            const { month, spend_category, attributed_sales } = curr;
            const monthDate = new Date(month).toISOString().split('T')[0];
            if (!acc[monthDate]) {
                acc[monthDate] = { month: monthDate };
            }
            acc[monthDate][spend_category] = parseFloat(attributed_sales);
            return acc;
        }, {});
        setFormattedGraphData(Object.values(formattedData));
    };

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const user = await Auth.currentAuthenticatedUser();
                setIsAuthenticated(!!user);
            } catch (err) {
                console.log('User not authenticated');
                setIsAuthenticated(false);
            }
        };
        checkAuth();
    }, []);

    useEffect(() => {
        const loadData = async () => {
            if (isAuthenticated && customerData) {
                await fetchData(modelRunDate);
            }
        };

        loadData();
    }, [isAuthenticated, customerData, modelRunDate]);

    const handleSignOut = async () => {
        try {
            await Auth.signOut();
            navigate('/signed-out');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const handleChange = (date) => {
        setModelRunDate(date);
        setIsDropdownOpen(false);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleLegendClick = (dataKey) => {
        setHighlightedCategory(dataKey);
        setTimeout(() => {
            setHighlightedCategory(null);
        }, 2000);
    };

    const scrollLegendLeft = () => {
        setLegendScrollIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    const scrollLegendRight = () => {
        setLegendScrollIndex((prevIndex) => prevIndex + 1);
    };

    const renderLegend = (props) => {
        const { payload } = props;
        const itemsToShow = 10;
        const startIndex = legendScrollIndex;
        const endIndex = Math.min(legendScrollIndex + itemsToShow, payload.length);
        const visibleItems = payload.slice(startIndex, endIndex);

        return (
            <div className="custom-legend">
                {startIndex > 0 && <span className="arrow" onClick={scrollLegendLeft}>{"<"}</span>}
                {visibleItems.map((entry, index) => (
                    <span 
                        key={`item-${index}`} 
                        style={{ margin: '0 10px', cursor: 'pointer' }} 
                        onClick={() => handleLegendClick(entry.value)}
                    >
                        <span style={{ color: entry.color }}>{entry.value}</span>
                    </span>
                ))}
                {endIndex < payload.length && <span className="arrow" onClick={scrollLegendRight}>{">"}</span>}
            </div>
        );
    };

    return (
        <div className="marketingAttributionSummary">
            <Sidebar handleSignOut={handleSignOut} />
            <div className="mainContent">
                <TopBar handleSignOut={handleSignOut} />
                <div className="breadcrumbs">
                    <div className="pageName">Marketing Attribution Summary</div>
                    <div className="breadcrumbsChild"></div>
                </div>
                <div className="contentArea">
                    <div className="driversOfGross">Drivers of gross signed volume</div>
                    <div className="modelRunDateContainer">
                        <div className="modelRunDate">Model Run Date</div>
                        <div className="dropdownWrapper">
                            <div className="customSelect" onClick={toggleDropdown}>
                                <span>{formatDate(modelRunDate)}</span>
                                <img className="dropdownIcon" alt="Dropdown Icon" src={dropdownIcon} />
                            </div>
                            {isDropdownOpen && (
                                <div className="dropdownMenu">
                                    {modelRunDates.map(date => (
                                        <div
                                            key={date}
                                            className="dropdownItem"
                                            onClick={() => handleChange(date)}
                                        >
                                            {formatDate(date)} - {(modelData[date]?.sales / 1000).toFixed(1)}K
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="marketingAttributionSummaryInner">
                        <div className="rectangleParent">
                            <div className="groupChild"></div>
                            <div className="flagAltParent">
                                <div className="flagIconContainer"></div>
                                <div className="modelFit">Model Fit</div>
                                <div className="flagIconContainer">
                                    <img className="flagAltIcon" alt="" src={flagIcon} />
                                </div>
                            </div>
                            <div className="lineParent">
                                <div className="groupItem"></div>
                                <div className="rsqParent">
                                    <div className="nrmse">RSQ</div>
                                    <b className="b">{modelData[modelRunDate]?.rsq}</b>
                                </div>
                                <div className="nrmseParent">
                                    <div className="nrmse">NRMSE</div>
                                    <b className="b">{modelData[modelRunDate]?.nrmse}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="graphContainer">
                        <ResponsiveContainer width="100%" height={400}>
                            <LineChart data={formattedGraphData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis 
                                    dataKey="month" 
                                    tickFormatter={(tick) => {
                                        const date = new Date(tick);
                                        return `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
                                    }}
                                />
                                <YAxis />
                                <Tooltip />
                                <Legend onClick={(e) => handleLegendClick(e.dataKey)} />
                                {formattedGraphData.length > 0 && Object.keys(formattedGraphData[0])
                                    .filter(key => key !== 'month' && key !== 'model_run_date')
                                    .map((key, index) => (
                                        <Line 
                                            key={key} 
                                            type="monotone" 
                                            dataKey={key} 
                                            stroke={`hsl(${index * 30}, 70%, 50%)`}
                                            strokeWidth={2}
                                            opacity={highlightedCategory && highlightedCategory !== key ? 0.2 : 1}
                                        />
                                    ))
                                }
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    <div className="additionalSpendContainer">
                        <h3>Impact of Additional Spend</h3>
                        <p>Annualized signups generated by spending ${additionalSpendAmount} more each month.</p>
                        <ResponsiveContainer width="100%" height={400}>
                            <BarChart 
                                data={additionalSpendData.sort((a, b) => b.incremental_sales - a.incremental_sales)}
                                layout="vertical"
                                margin={{ top: 20, right: 30, left: 150, bottom: 20 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis type="number" />
                                <YAxis type="category" dataKey="spend_category" width={180} />
                                <Tooltip />
                                <Bar dataKey="incremental_sales" fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                    <div className="saturationCurveContainer">
                        <h3>Channel-Specific Saturation Curves</h3>
                        <p>Annualized signups generated by spending ${additionalSpendAmount} more each month.</p>
                        <ResponsiveContainer width="100%" height={400}>
                            <LineChart data={saturationCurveData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis type="number" dataKey="spend_adstocked" />
                                <YAxis />
                                <Tooltip />
                                <Legend content={renderLegend} />
                                {saturationCurveData.length > 0 && Object.keys(saturationCurveData[0])
                                    .filter(key => key !== 'model_run_date' && key !== 'spend_adstocked')
                                    .map((key, index) => (
                                        <Line 
                                            key={key} 
                                            type="monotone" 
                                            dataKey={key} 
                                            stroke={`hsl(${index * 30}, 70%, 50%)`}
                                            strokeWidth={2}
                                            opacity={highlightedCategory && highlightedCategory !== key ? 0.2 : 1}
                                            dot={false} 
                                        />
                                    ))
                                }
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Overview;
