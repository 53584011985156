import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import './GoogleAdsSC.css';

const GoogleAdsSC = () => {
    const navigate = useNavigate();
    const [campaignName, setCampaignName] = useState('Campaign 1');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [conversionData, setConversionData] = useState([]);
    const [deliveryData, setDeliveryData] = useState([]);
    const [yesterdayData, setYesterdayData] = useState({});
    const [last7DaysData, setLast7DaysData] = useState({});
    const [monthToDateData, setMonthToDateData] = useState({});

    const handleSignOut = async () => {
        try {
            await Auth.signOut();
            navigate('/signed-out');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const formatNumber = (num, decimals = 0) => {
        return num.toLocaleString('en-US', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
    };

    const fetchData = async (url, setData, isAggregated = false) => {
        try {
            const response = await axios.get(url);
            const responseBody = response.data.body ? JSON.parse(response.data.body) : response.data;
            //console.log('Response Body:', responseBody);

            const data = responseBody.data;
            console.log('Parsed Data:', data);

            if (Array.isArray(data) && data.length > 0) {
                if (isAggregated) {
                    const aggregatedData = data.reduce((acc, item) => {
                        acc.impressions += parseInt(item.metrics_impressions || 0);
                        acc.clicks += parseInt(item.metrics_clicks || 0);
                        acc.cost += parseFloat(item.metrics_cost_micros || 0) / 1000000;
                        acc.conversions += parseFloat(item.metrics_conversions || 0);
                        acc.ctr += parseFloat(item.metrics_ctr || 0) * 100;
                        acc.cpc += parseFloat(item.metrics_average_cpc || 0) / 1000000;
                        acc.cpcvr += parseFloat(item.metrics_cost_per_conversion || 0) / 1000000;
                        return acc;
                    }, {
                        impressions: 0,
                        clicks: 0,
                        cost: 0,
                        conversions: 0,
                        ctr: 0,
                        cpc: 0,
                        cpcvr: 0
                    });

                    aggregatedData.ctr = (aggregatedData.ctr / data.length).toFixed(2);
                    aggregatedData.cpc = (aggregatedData.cpc / data.length).toFixed(2);
                    aggregatedData.cpcvr = (aggregatedData.cpcvr / data.length).toFixed(2);
                    aggregatedData.cost = aggregatedData.cost.toFixed(2);
                    aggregatedData.impressions = formatNumber(aggregatedData.impressions);
                    aggregatedData.clicks = formatNumber(aggregatedData.clicks);
                    aggregatedData.conversions = formatNumber(aggregatedData.conversions);
                    aggregatedData.ctr = formatNumber(aggregatedData.ctr, 2);
                    aggregatedData.cpc = formatNumber(aggregatedData.cpc, 2);
                    aggregatedData.cpcvr = formatNumber(aggregatedData.cpcvr, 2);
                    aggregatedData.cost = formatNumber(aggregatedData.cost, 2);

                    setData(aggregatedData);
                } else {
                    const groupedData = data.reduce((acc, item) => {
                        const week = item.segments_week;
                        if (!acc[week]) {
                            acc[week] = {
                                conversions: 0,
                                cost: 0,
                                cpcvr: 0,
                                value: 0,
                                valuePerConversion: 0,
                                impressions: 0,
                                clicks: 0,
                                cpc: 0,
                                ctr: 0
                            };
                        }
                        acc[week].conversions += parseFloat(item.metrics_conversions || 0);
                        acc[week].cost += parseFloat(item.metrics_cost_micros || 0) / 1000000;
                        acc[week].cpcvr += parseFloat(item.metrics_cost_per_conversion || 0) / 1000000;
                        acc[week].value += parseFloat(item.metrics_conversions_value || 0);
                        acc[week].valuePerConversion += parseFloat(item.metrics_value_per_conversion || 0);
                        acc[week].impressions += parseInt(item.metrics_impressions || 0);
                        acc[week].clicks += parseInt(item.metrics_clicks || 0);
                        acc[week].cpc += parseFloat(item.metrics_average_cpc || 0) / 1000000;
                        acc[week].ctr += parseFloat(item.metrics_ctr || 0) * 100;
                        return acc;
                    }, {});

                    const parsedConversionData = Object.entries(groupedData).map(([week, data]) => ({
                        week,
                        conversions: formatNumber(data.conversions),
                        cost: `$${formatNumber(data.cost, 2)}`,
                        cpcvr: `$${formatNumber(data.cpcvr, 2)}`,
                        value: `$${formatNumber(data.value, 2)}`,
                        valuePerConversion: `$${formatNumber(data.valuePerConversion, 2)}`
                    }));

                    const parsedDeliveryData = Object.entries(groupedData).map(([week, data]) => ({
                        week,
                        cost: `$${formatNumber(data.cost, 2)}`,
                        impressions: formatNumber(data.impressions),
                        clicks: formatNumber(data.clicks),
                        cpc: `$${formatNumber(data.cpc, 2)}`,
                        ctr: `${formatNumber(data.ctr, 2)}%`
                    }));

                    setConversionData(parsedConversionData);
                    setDeliveryData(parsedDeliveryData);
                }
            } else {
                setData({
                    impressions: 'No Data',
                    clicks: 'No Data',
                    cost: 'No Data',
                    conversions: 'No Data',
                    ctr: 'No Data',
                    cpc: 'No Data',
                    cpcvr: 'No Data'
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            console.error('Error details:', {
                message: error.message,
                config: error.config,
                response: error.response ? {
                    data: error.response.data,
                    status: error.response.status,
                    headers: error.response.headers,
                } : null
            });
            setData({
                impressions: 'No Data',
                clicks: 'No Data',
                cost: 'No Data',
                conversions: 'No Data',
                ctr: 'No Data',
                cpc: 'No Data',
                cpcvr: 'No Data'
            });
        }
    };

    useEffect(() => {
        const fetchAllData = async () => {
            await fetchData('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/google-ads-sc-twelve', (data) => {
                if (Array.isArray(data) && data.length > 0) {
                    const groupedData = data.reduce((acc, item) => {
                        const week = item.segments_week;
                        if (!acc[week]) {
                            acc[week] = {
                                conversions: 0,
                                cost: 0,
                                cpcvr: 0,
                                value: 0,
                                valuePerConversion: 0,
                                impressions: 0,
                                clicks: 0,
                                cpc: 0,
                                ctr: 0
                            };
                        }
                        acc[week].conversions += parseFloat(item.metrics_conversions || 0);
                        acc[week].cost += parseFloat(item.metrics_cost_micros || 0) / 1000000;
                        acc[week].cpcvr += parseFloat(item.metrics_cost_per_conversion || 0) / 1000000;
                        acc[week].value += parseFloat(item.metrics_conversions_value || 0);
                        acc[week].valuePerConversion += parseFloat(item.metrics_value_per_conversion || 0);
                        acc[week].impressions += parseInt(item.metrics_impressions || 0);
                        acc[week].clicks += parseInt(item.metrics_clicks || 0);
                        acc[week].cpc += parseFloat(item.metrics_average_cpc || 0) / 1000000;
                        acc[week].ctr += parseFloat(item.metrics_ctr || 0) * 100;
                        return acc;
                    }, {});

                    const parsedConversionData = Object.entries(groupedData).map(([week, data]) => ({
                        week,
                        conversions: formatNumber(data.conversions),
                        cost: `$${formatNumber(data.cost, 2)}`,
                        cpcvr: `$${formatNumber(data.cpcvr, 2)}`,
                        value: `$${formatNumber(data.value, 2)}`,
                        valuePerConversion: `$${formatNumber(data.valuePerConversion, 2)}`
                    }));

                    const parsedDeliveryData = Object.entries(groupedData).map(([week, data]) => ({
                        week,
                        cost: `$${formatNumber(data.cost, 2)}`,
                        impressions: formatNumber(data.impressions),
                        clicks: formatNumber(data.clicks),
                        cpc: `$${formatNumber(data.cpc, 2)}`,
                        ctr: `${formatNumber(data.ctr, 2)}%`
                    }));

                    setConversionData(parsedConversionData);
                    setDeliveryData(parsedDeliveryData);
                } else {
                    setConversionData([]);
                    setDeliveryData([]);
                }
            }, false);
            await new Promise(resolve => setTimeout(resolve, 500));
            await fetchData('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/google-ads-sc-yesterday', setYesterdayData, true);
            await new Promise(resolve => setTimeout(resolve, 500));
            await fetchData('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/google-ads-sc-seven', setLast7DaysData, true);
            await new Promise(resolve => setTimeout(resolve, 500));
            await fetchData('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/google-ads-sc-month', setMonthToDateData, true);
        };

        fetchAllData();
    }, []);

    return (
        <div className="googleAdsSummary">
            <Sidebar handleSignOut={handleSignOut} />
            <div className="mainContent">
                <TopBar handleSignOut={handleSignOut} />
                <div className="breadcrumbs">
                    <div className="pageName">Google Ads Summary</div>
                </div>
                <div className="contentArea">
                    <div className="summaryHeader">
                        <div className="accountName">ACCOUNT | 954-374-0159 GS - PI SC</div>
                        <div className="campaignDropdown">
                            <div className="customSelect" onClick={toggleDropdown}>
                                <span>{campaignName}</span>
                                <span className="dropdownIcon">▼</span>
                            </div>
                            {isDropdownOpen && (
                                <div className="dropdownMenu">
                                    {['Campaign 1', 'Campaign 2', 'Campaign 3'].map(name => (
                                        <div
                                            key={name}
                                            className="dropdownItem"
                                            onClick={() => {
                                                setCampaignName(name);
                                                setIsDropdownOpen(false);
                                            }}
                                        >
                                            {name}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="activitySections">
                        <ActivitySection
                            title="Yesterday"
                            data={yesterdayData}
                        />
                        <ActivitySection
                            title="Last 7 Days (excluding today)"
                            data={last7DaysData}
                        />
                        <ActivitySection
                            title="Month to Date (vs prior MTD, excluding today)"
                            data={monthToDateData}
                        />
                    </div>
                    <div className="weeklyTrends">WEEKLY TRENDS | Last 12 weeks</div>
                    <ConversionTable data={conversionData} />
                    <DeliveryTable data={deliveryData} />
                </div>
            </div>
        </div>
    );
};

const ActivitySection = ({ title, data }) => (
    <div className="activitySection">
        <div className="activityHeader">{title}</div>
        <div className="activityData">
            {Object.entries(data).map(([key, value]) => (
                <div key={key} className="activityItem">
                    <div className="activityLabel">{key}</div>
                    <div className="activityValue">{value}</div>
                </div>
            ))}
        </div>
    </div>
);

const ConversionTable = ({ data }) => (
    <div className="conversionTable">
        <div className="tableHeader">CONVERSION</div>
        <table>
            <thead>
                <tr>
                    <th>Week</th>
                    <th>Conversions</th>
                    <th>Cost</th>
                    <th>CPCvr</th>
                    <th>Value</th>
                    <th>Value per Conversion</th>
                </tr>
            </thead>
            <tbody>
                {data.map((row, index) => (
                    <tr key={index}>
                        <td>{row.week}</td>
                        <td>{row.conversions}</td>
                        <td>{row.cost}</td>
                        <td>{row.cpcvr}</td>
                        <td>{row.value}</td>
                        <td>{row.valuePerConversion}</td>
                    </tr>
                ))}
            </tbody>
            <tfoot>
                <tr>
                    <td>Grand total</td>
                    <td>{data.reduce((acc, row) => acc + parseFloat(row.conversions.replace(/,/g, '')), 0)}</td>
                    <td>{`$${data.reduce((acc, row) => acc + parseFloat(row.cost.replace(/[^0-9.-]+/g, '')), 0).toFixed(2)}`}</td>
                    <td>{`$${(data.reduce((acc, row) => acc + parseFloat(row.cpcvr.replace(/[^0-9.-]+/g, '')), 0) / data.length).toFixed(2)}`}</td>
                    <td>{`$${data.reduce((acc, row) => acc + parseFloat(row.value.replace(/[^0-9.-]+/g, '')), 0).toFixed(2)}`}</td>
                    <td>{`$${(data.reduce((acc, row) => acc + parseFloat(row.valuePerConversion.replace(/[^0-9.-]+/g, '')), 0) / data.length).toFixed(2)}`}</td>
                </tr>
            </tfoot>
        </table>
    </div>
);

const DeliveryTable = ({ data }) => (
    <div className="deliveryTable">
        <div className="tableHeader">DELIVERY</div>
        <table>
            <thead>
                <tr>
                    <th>Week</th>
                    <th>Cost</th>
                    <th>Impressions</th>
                    <th>Clicks</th>
                    <th>CPC</th>
                    <th>CTR</th>
                </tr>
            </thead>
            <tbody>
                {data.map((row, index) => (
                    <tr key={index}>
                        <td>{row.week}</td>
                        <td>{row.cost}</td>
                        <td>{row.impressions}</td>
                        <td>{row.clicks}</td>
                        <td>{row.cpc}</td>
                        <td>{row.ctr}</td>
                    </tr>
                ))}
            </tbody>
            <tfoot>
                <tr>
                    <td>Grand total</td>
                    <td>{`$${data.reduce((acc, row) => acc + parseFloat(row.cost.replace(/[^0-9.-]+/g, '')), 0).toFixed(2)}`}</td>
                    <td>{data.reduce((acc, row) => acc + parseInt(row.impressions.replace(/,/g, '')), 0)}</td>
                    <td>{data.reduce((acc, row) => acc + parseInt(row.clicks.replace(/,/g, '')), 0)}</td>
                    <td>{`$${(data.reduce((acc, row) => acc + parseFloat(row.cpc.replace(/[^0-9.-]+/g, '')), 0) / data.length).toFixed(2)}`}</td>
                    <td>{`${(data.reduce((acc, row) => acc + parseFloat(row.ctr.replace(/[^0-9.-]+/g, '')), 0) / data.length).toFixed(2)}%`}</td>
                </tr>
            </tfoot>
        </table>
    </div>
);

export default GoogleAdsSC;
