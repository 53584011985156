import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useLocation, useNavigate } from 'react-router-dom';

const CustomAuthenticator = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const checkUser = async () => {
            try {
                await Auth.currentAuthenticatedUser();
                setIsAuthenticated(true);
            } catch {
                setIsAuthenticated(false);
                if (location.pathname !== '/' && location.pathname !== '/signed-out') {
                    navigate('/');
                }
            }
        };

        checkUser();
    }, [location.pathname, navigate]);

    const handleGoogleSignIn = () => {
        Auth.federatedSignIn({ provider: 'Google' });
    };

    const handleSignOut = async () => {
        try {
            await Auth.signOut();
            localStorage.removeItem('userToken'); 
            localStorage.removeItem('userEmail'); 
            setIsAuthenticated(false);
            navigate('/signed-out');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    return (
        <div>
            {!isAuthenticated && location.pathname !== '/signed-out' && location.pathname !== '/' && (
                <button onClick={handleGoogleSignIn} style={{ position: 'absolute', top: 10, left: 10 }}>Sign in with Google</button>
            )}
            {children(isAuthenticated, handleSignOut)}
        </div>
    );
};

export default CustomAuthenticator;
