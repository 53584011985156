import React from 'react';
import { NavLink } from 'react-router-dom';
import './AdminSidebar.css'; // Ensure you have custom styles for admin sidebar

const AdminSidebar = () => {
    return (
        <div className="sidebar">
            <div className="sidebar-header">
                <div className="logo-text">Admin Dashboard</div>
            </div>

            <div className="menuContainer">
                <NavLink to="/admin" className="sidebarButton" activeClassName="active">
                    Main Admin Page
                </NavLink>
                <NavLink to="/admin-select-customer" className="sidebarButton" activeClassName="active">
                    Impersonate Customer
                </NavLink>
                <NavLink to="/customer-enrollment" className="sidebarButton" activeClassName="active">
                    Customer Enrollment
                </NavLink>
            </div>
        </div>
    );
};

export default AdminSidebar;
